.page-account {
    .page-section {
        @media (max-width: 767px) {
            padding-top: .75rem;
        }
    }

    .page-account-title {
        margin-bottom: 1rem;
    }

    .sec-header {
        margin-bottom: 1rem;

        .sec-title {
            font-size: calc(1.275rem + 0.3vw);
            margin-bottom: .25rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .sec-description {
            font-size: 1rem;
            max-width: 100%;
        }
    }

    .accordion {
        .accordion-button {
            box-shadow: none !important;
            font-weight: 700;
        }
    }
}

.inapp-page-header {
    position: relative;
    background-image: url('../../../assets/images/membership/footer-img-market-1.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    .container, .container-xl {
        position: relative;
        z-index: 1;
    }
}

.account-layout {
    display: flex;
    flex-direction: row;

    @media (max-width: 767px) {
        flex-direction: column;
    }
}

.account-layout-nav {
    width: 100%;

    @media (min-width: 768px) {
        width: 300px;
        flex: 0 0 300px;
        padding-right: 1.5rem;
        border-right: 1px solid var(--bs-gray-300);
    }

    .btn-account-navbar-toggler {
        padding: .75rem 1rem;
        box-shadow: none !important;
        border: none;

        @media (min-width: 768px) {
            display: none;
        }
    }

    .account-layout-navbar {
        @media (max-width: 767px) {
            border-radius: 0 0 1rem 1rem;
            box-shadow: 2px 2px 8px rgba(0, 0, 0, .15);

            .nav {
                padding: 1rem;
            }
        }
    }

    .nav-link {
        padding: .75rem 1rem;
        font-weight: 600;
    }
}

.account-layout-content {
    flex: 0 1 calc(100% - 300px);
    max-width: calc(100% - 300px);
    padding-left: 1.5rem;

    @media (max-width: 767px) {
        padding: 1.5rem 0;
        flex: 0 1 100%;
        max-width: 100%;
    }

    .card {
        color: #000;

        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }

        .card-header {
            padding: .75rem 1rem;

            .card-title {
                margin: 0;
            }
        }
    }
}

.membership-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 400px;
    height: 570px;
    max-width: 100%;
    margin: 0 auto;
    padding: 2rem;
    text-align: left;
    color: #FFF;
    background-color: var(--bs-primary);
    background-image: url('../../../assets/images/account/the-rau-bg.jpg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 2.5rem;
    text-align: center;
    box-shadow: 4px 4px 32px rgba(0, 0, 0, .35);

    @media (max-width: 767px) {
        width: 340px;
        height: 490px;
        
        &.inapp {
            margin-top: -64px;
        }
    }

    .card-owner {
        font-family: 'Montserrat', sans-serif;
        font-style: italic;
        font-size: 1.5rem;
        font-weight: 800;
        text-transform: uppercase;
        color: #FFF;
        margin-bottom: .5rem;
        border-radius: .35rem;
        text-shadow: 1px 1px 4px rgba(0, 0, 0, .35);

        @media (min-width: 768px) {
            font-size: 1.75rem;
        }
    }

    .card-valid-date {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        font-weight: 600;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, .15);

        p {
            margin: 0;
        }

        span {
            font-size: .875rem;
            font-weight: 300;
        }
    }
}

.no-membership {
    text-align: center;
    width: 600px;
    max-width: 100%;
    margin: auto;
    
    img {
        width: 350px;
    }
}

.table-redeem-history {
    &.inapp {
        margin: 0 -.5rem;

        thead {
            display: none !important;
        }

        td {
            // padding: 0 !important;
        }
    }
    
    th {
        background-color: var(--bs-gray-200);
        white-space: nowrap;
    }

    td {
        vertical-align: middle;
    }

    .btn {
        white-space: nowrap;
    }
}

.redeem-history-item-card {
    display: flex;
    flex-direction: row;
    padding: .75rem;
    // margin-bottom: .5rem;
    background-color: #FFF;
    border-radius: .5rem;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, .1);

    .redeem-title {
        margin-bottom: 2px;
    }

    .redeem-type {
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        flex-shrink: 0;
        margin-right: .75rem;

        &.delivery {
            background-color: var(--bs-primary);
        }

        &.pickup {
            background-color: var(--bs-success);
        }
    }

    .redeem-history-item-meta {
        font-size: .75rem;
        margin: 0;

        &:not(:last-child) {
            margin-bottom: .5rem;
        }
    }
}

.redeem-confirmation-code {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    font-size: 2.5rem;
    font-weight: 600;
    border-radius: 50%;
    background-color: var(--bs-gray-200);
    margin: auto;
}

.table-report {
    font-size: .875rem;

    th {
        white-space: nowrap;
    }

    td {
        font-family: 'Manrope', sans-serif;
    }

    .btn-table-expand {
        border: none !important;
        box-shadow: none !important;
        line-height: 1;
        vertical-align: text-bottom;
    }

    .btn-view-referral {
        padding: 0.125rem 0.25rem;
        font-size: .75rem;
    }
}

.table-report-footer {
    font-family: 'Manrope', sans-serif;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
        flex-direction: column;
        gap: .5rem
    }

    * {
        font-size: .875rem !important;
    }

    .table-report-footer-group {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        gap: .25rem;
    }

    .btn-sm {
        border: none !important;
    }
}

.account-report-tabs {
    .nav-link {
        &.active {
            font-weight: 600;
        }
    }
}

.account-report-tab-content {
}