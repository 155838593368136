.page-buy-membership {
    color: #000 !important;
    background-color: #FFF !important;

    .page-header {
        position: relative;
        background-image: url('../../../assets/images/membership/footer-img-market-1.png');
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;

        &::after {
            content: '';
            position: absolute;
            z-index: 0;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-image: url('../../../assets/images/section-6/bg-img.png');
            background-position: bottom right;
            background-repeat: no-repeat;
            background-size: 50%;
        }

        .container, .container-xl {
            position: relative;
            z-index: 1;
        }
    }

    .form-floating {
        label {
            color: #000 !important;
            font-weight: 600;
        }
        .form-control, .form-select {
            color: #000 !important;
        }
    }

    .payment-preview {
        td {
            &:nth-child(1) {
                padding-left: 0;
            }
            
            &:nth-child(2) {
                padding-right: 0;
                text-align: right;
                font-weight: 700;
            }
        }
    }

    .active-preview {
        padding: 1.5rem;
        border-radius: 1rem;
        border: 1px solid var(--bs-success);
        background-color: rgba(135, 197, 35, .1);
        background-image: url('../../../assets/images/membership/footer-img-market-2.png');
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: 100%;

        .table {
            background-color: transparent;
        }

        td {
            background-color: transparent;

            &:nth-child(1) {
                padding-left: 0;
            }
            
            &:nth-child(2) {
                padding-right: 0;
                text-align: right;
                font-weight: 700;
            }
        }
    }
}