@import 'assets/scss/variables.scss';

.content-page-section {
    background-color: #FFF;

    h2 {
        margin-top: 2rem;
        margin-bottom: .75rem;
    }

    img {
        max-width: 100% !important;
        height: auto !important;
    }
}