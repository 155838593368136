.page-payment {
    color: #000 !important;
    background-color: #FFF !important;

    .page-header {
        background-image: url('../../../assets/images/section-6/bg-img.png');
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 50%;
        min-height: 100vh;

        .sec-header {
            .sec-title {
                @media (max-width: 767px) {
                    font-size: 1.75rem;
                }
            }
            
            .sec-description {
                font-size: 1rem;
            }
        }

        .sec-content {
            color: #000;
            background-color: #FFF;
            border-radius: 1rem;
        }
    }

    .form-floating {
        label {
            color: #000 !important;
            font-weight: 600;
        }
        .form-control, .form-select {
            color: #000 !important;
        }
    }

    .payment-preview {
        td {
            &:only-child {
                padding-left: 0;
                padding-right: 0;
            }

            &:nth-child(1) {
                padding-left: 0;
            }

            &:nth-child(2) {
                padding-right: 0;
                text-align: right;
                font-weight: 700;
            }
        }
    }

    .pincode-input-container {
        .pincode-input-text {
            @media (max-width: 375px) {
                width: 58px !important;
                margin: 0 .375rem !important;
            }
        }
    }
}

.transaction-image {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    padding: .5rem;
    text-align: center;
    border: 1px solid var(--bs-gray-200);
    border-radius: var(--bs-border-radius);

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}