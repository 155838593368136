@import 'assets/scss/variables.scss';

.page-home {
    color: #FFF;
}

.home-header {
    position: relative;
    padding: 0;

    .section-bg {
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: .1;
    }

    .slogan-item {
        padding: 1.5rem;
        border: 2px solid rgba(255,255,255,.2);
        border-radius: 1.5rem;
        color: #FFF;
        background-color: rgba(255,255,255,.1);
        font-size: 1.5rem;
        text-transform: uppercase;

        p {
            margin: 0;
        }
    }

    .home-header-slider {
        .home-header-slider-item {
            width: 100%;
            height: 70vh;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}

.about-section {
    .section-bg {
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: .1;
    }

    .sec-header {
        .sec-title {
            margin-bottom: 2rem;
        }

        .sec-description {
            font-size: 1.25rem;
        }
    }

    .sec-content {
        font-size: .875rem;
    }

    .about-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
            margin: 0;
        }
    }

    .btn-lg {
        font-size: 1rem;
    }

    .about-text {
        position: relative;
        z-index: 2;
    }

    .about-img {
        position: relative;
        z-index: 1;
        
        @media (min-width: 768px) {
            transform: translateX(-10%);
        }
    }
}

.story-section {
    color: var(--bs-primary);
    background-color: #FFF;

    .story-image {
        position: relative;
        width: 100%;
        margin: auto;

        .story-card-flying-1 {
            position: absolute;
            top: 0;
            left: 33%;
            width: 28%;
            height: auto;
            transition: transform .15s ease;
            animation: bounce-rotate 2s infinite ease;
        }

        .story-card-flying-2 {
            position: absolute;
            bottom: 0;
            right: 5%;
            width: 33%;
            height: auto;
            transition: transform .15s ease;
            animation: bounce-left 2s infinite ease;
        }
    }

    .story-letter-wrapper {
        position: relative;
        font-size: .875rem;
        padding: 2rem 1.5rem;
        color: #FFF;
        background-color: var(--bs-primary);
        border-radius: 1rem;
        margin-bottom: 4rem;
        background-image: url('../../../assets/images/membership/footer-img-market-1.png');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;

        .letter-quotes {
            position: absolute;
            font-size: 5rem;

            &.open {
                top: -64px;
                left: 1.5rem;
            }

            &.close {
                bottom: -68px;
                right: 1.5rem;
            }
        }
    }
}

@keyframes bounce-rotate {
    0%      { transform: rotateZ(0); }
    50%     { transform: rotateZ(15deg); }
    100%    { transform: rotateZ(0); }
}

@keyframes bounce-left {
    0%      { transform: translateX(0) rotateZ(0); }
    50%     { transform: translateX(-30%) rotateZ(-5deg); }
    100%    { transform: translateX(0) rotateZ(0); }
}

.highlight-section {
    background: linear-gradient(rgb(1, 11, 173), rgb(0, 74, 173));

    .highlight-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 1rem;
        background-color: rgba(1, 75, 173, 0.5);
        box-shadow: rgb(0, 6, 96) 0px 15px 26px -20px;
        padding: 1.5rem;
        height: 100%;

        @media (max-width: 767px) {
            min-height: 160px;
        }

        .highlight-icon {
            width: 72px;
            height: auto;
            margin-right: 1rem;
        }

        h5 {
            margin-bottom: 2px;
            white-space: nowrap;
        }

        p {
            font-size: .875rem;
            margin-bottom: 0;

            span {
                font-size: 1.5rem;
                font-weight: 500;
            }
        }
    }

    .highlight-target-steps {
        .highlight-line {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0 15%;

            .line-dotted {
                flex-grow: 1;
                height: .5px;
                border: .5px dashed #FFF;
                margin: 0 2px;
            }
        }

        p {
            font-size: .625rem;
            margin-bottom: 1rem;
        }
    }
}

.partner-section {
    color: var(--bs-primary);
    background-color: #FFF;
}

.branches-section {
	.section-bg {
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: .1;
    }

    .branch-slider-item-wrapper {
        width: 100%;
        height: 100%;
    }
    .branch-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 1.5rem;
        border-radius: .75rem;
        overflow: hidden;
        color: var(--bs-primary);
        background-color: #FFF;
        filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, .15));

        .item-icon {
            margin-bottom: 1rem;
        }

        .item-info {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .item-name {
                margin-bottom: .25rem;
            }
            .item-address {
                color: var(--bs-gray-600);
                margin-bottom: 0;
            }
        }
    }
}

#branchesModal {
    color: #000;

    .modal-body {
        padding-top: 3rem;
        background-color: #e8f7fd;
        background-image: url('../../../assets/images/section-6/bg-img.png');
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 50%;
    }

    .branch-item {
        filter: drop-shadow(2px 2px 16px rgba(0, 0, 0, .05));
    }
}

.news-section {
    color: var(--bs-primary);
    background-color: #FFF;

    .news-slider-item-wrapper {
        width: 100%;
        padding: 18px;
    }
    
    .news-item {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: .25rem;
        overflow: hidden;
        background-color: #FFF;
        filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, .05));

        .article-logo {
            position: absolute;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #FFF;
            width: 100px;
            height: 60px;
            border-bottom-right-radius: .5rem;
            padding: .25rem;
        }
    
        .article-image {
            position: relative;
            z-index: 1;
            height: 230px;
            overflow: hidden;
    
            &:hover {
                img {
                    transform: scale(1.15);
                }
            }
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform .25s ease;
            }
        }
    
        .article-info {
            padding: 1rem .75rem;
    
            .article-title {
                font-size: 1.125rem;
                margin-bottom: .25rem;
            }

            .article-description {
                font-size: .875rem;
                color: var(--bs-gray-600);
                margin-bottom: 0;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                align-self: stretch;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}