/*--------------------------------------------------------------
# Layouts
--------------------------------------------------------------*/
.site {
	position: relative;
	min-height: 100vh;
	position: relative;
	overflow: hidden;
}

.page-layout {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    min-height: 100vh;
    transition: all .15s ease;
}

.page-content {
    background-color: #FFF !important;
    color: #000 !important;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.top-navbar {
    background-color: rgba(0, 79, 249, .5);
    backdrop-filter: blur(20px);

    @media (max-width: 767px) {
        padding-top: .75rem;
        padding-bottom: .75rem;
    }
    
    .navbar-brand {
        font-weight: 600;

        img {
            width: 160px;
            height: 40px;

            @media (max-width: 767px) {
                width: 128px;
                height: 32px;
            }
        }
    }

    .navbar-nav {
        .dropdown {
            .dropdown-menu {
                z-index: 1030;
                opacity: 0;
                border-color: var(--bs-gray-300);
                color: #FFF;
                background-image: linear-gradient(rgb(48, 86, 255), rgb(1, 69, 160));

                &.show {
                    opacity: 1;
                    animation: fadeIn 150ms ease;
                }

                .dropdown-item {
                    font-size: .875rem;
                    font-weight: 500;
                    color: #FFF;
                    padding: .5rem var(--bs-gutter-x, 0.75rem);

                    &:hover, &:active, &.active {
                        color: #FFF;
                        background-color: rgba(255, 255, 2555, 0.2);
                    }
                }

                .dropdown-divider {
                    border-color: var(--bs-gray-200);
                }

                @media (min-width: 992px) {
                    border: none;
                    margin-top: 1rem;
                    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
                }

            }
        }

        .nav-item {
            .nav-link {
                padding-left: 1rem;
                padding-right: 1rem;
                color: #FFF;
                font-size: .875rem;
                font-weight: 500;

                @media (max-width: 767px) {
                    padding-left: .25rem;
                    padding-right: .25rem;
                }
            }
        }

        .btn {
            font-size: .875rem;
        }
    }

    .navbar-user-dropdown {
        color: #000;

        @media (min-width: 992px) {
            width: 160px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }

        .dropdown-toggle {
            width: 36px;
            height: 36px;
            padding: 0 !important;
            border-radius: 50%;
            overflow: hidden;

            &::after {
                content: '' !important;
                display: none !important;
            }

            img {
                width: 36px;
                height: 36px;
            }
        }

        .dropdown-menu {
            width: 280px;
            border: none;
            margin-top: 1rem;

            @media (max-width: 992px) {
                position: fixed;
                top: 44px;
                right: .5rem;
            }
        }

        .dropdown-item {
            color: #000;

            &:focus {
                color: #FFF;
            }
        }

        .navbar-user {
            color: #000;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-left: var(--bs-gutter-x, 0.75rem);
            padding-right: var(--bs-gutter-x, 0.75rem);

            .navbar-user-avatar {
                flex: 0 0 36px;
                width: 36px;
                height: 36px;
                padding: 0;
                border-radius: 50%;
                overflow: hidden;
                margin-right: .5rem;

                img {
                    width: 36px;
                    height: 36px;
                }
            }

            .navbar-user-info {
                flex: 1 1;
                width: calc(100% - 36px - .5rem);
                text-align: left;

                .navbar-user-fullname {
                    font-weight: 500;
                    line-height: 1.3;
                    margin-bottom: 0;
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                
                .navbar-user-username {
                    color: var(--bs-gray-600);
                    font-size: .75rem;
                    line-height: 1.3;
                    margin-bottom: 0;
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .navbar-toggler {
        border-color: transparent !important;
        padding: .35rem .25rem;
        box-shadow: none !important;
    }

    &.navbar-inapp {
        .not-inapp {
            display: none;
        }
    }
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.site-footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: #FFF;
    background-color: var(--bs-primary);

    .footer-brand {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 64px;
        width: 64px;
        overflow: hidden;
        font-weight: 600;
        font-size: 1.5rem;
        text-align: center;
        color: var(--bs-white);
        background-color: var(--bs-primary);
        border-radius: 16px;
        margin-right: auto;
        margin-bottom: 2rem;
    }

    .footer-links {
        list-style: none;
        padding-inline-start: 0;

        &.site-footer-company-info {
            li {
                &:not(:first-child) {
                    margin-top: .25rem;
                }

                a {
                    color: #FFF;
                    font-weight: 700;
                }

                img {
                    object-fit: contain;
                }
            }
        }
        
        li {
            margin-top: .625rem;

            a {
                color: #FFF;
                text-decoration: none;
            }
        }
    }

    .footer-policy-links {
        text-align: center;

        a {
            font-size: .75rem;
        }

        .col-6 {
            &:not(:last-child) {
                border-right: 1px solid #FFF;
                margin-bottom: 1rem;
            }
            &:nth-child(2n) {
                @media (max-width: 767px) {
                    border-right: none;   
                }
            }
        }
    }
}

.site-copyright {
	padding-top: 2rem;
	padding-bottom: 2rem;
	text-align: center;
	text-transform: uppercase;
}

/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/
.site-content {
    flex-grow: 1;
}