.shop-section {
    color: #000;
    background-color: #FFF;

    .sec-header {
        .sec-title {
            color: var(--bs-primary);
        }
    }
}

.shop-slider {
    margin-top: -18px;
}

.shop-slider-item-wrapper {
    width: 100%;
    padding: 18px;
}

.shop-item {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: .75rem;
    overflow: hidden;
    background-color: #FFF;
    filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, .15));

    .item-image {
        height: 230px;
        overflow: hidden;

        &:hover {
            img {
                transform: scale(1.15);
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform .25s ease;
        }
    }

    .item-info {
        padding: 1rem .75rem;

        .item-name {
            font-size: 1.125rem;
            margin-bottom: .25rem;
        }
        .item-unit {
            font-size: .875rem;
            color: var(--bs-gray-600);
            margin-bottom: 0;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            align-self: stretch;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.shop-item-detail-modal {
    .item-name {
        font-size: 2rem;
        margin-bottom: 1rem;
    }
}